import { createContext, useEffect, useState } from "react";
import Mappy from './components/Map';

import "./index.css";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

export const LocaleContext = createContext();

function App() {
  const [locale, setLocale] = useState("gr");
  const [rerenderKey, setRerenderKey] = useState(1);

  const getLocale = (string) => {
    if (string !== locale) {
      setLocale(string);
    }
  };

  useEffect(() => {
    if (locale) {
      setRerenderKey(rerenderKey + 1);
    }
  }, [locale]);

  return (
    <>
      <LocaleContext.Provider value={locale}>
        <Mappy key={rerenderKey} getLocale={getLocale} />
      </LocaleContext.Provider>
    </>
  );
}

export default App;
