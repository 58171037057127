import { Autocomplete, TextField } from "@mui/material";
import { useState } from "react";

import { useDebounce } from "./hooks/index";
import axios from "axios";

function Geocoder({ flyTo }) {
  const [value, setValue] = useState("");
  const [results, setResults] = useState([]);
  const [valueInput, setValueInput] = useState("");

  const handleSearch = useDebounce((term) => {
    axios
      .get(
        `https://nominatim.openstreetmap.org/search.php?q=${term}&accept-language=el&limit=5&namedetails=1&addressdetails=1&format=jsonv2`
      )
      .then((res) => {
        let { data } = res;
        if (data.length > 0) {
          let apiResults = data.map((result) => ({
            id: result?.osm_id,
            name: result?.display_name,
            bbox: result?.boundingbox,
          }));

          const uniqueOptions = apiResults.reduce((accumulator, current) => {
            if (!accumulator.find((item) => item.name === current.name)) {
              accumulator.push(current);
            }
            return accumulator;
          }, []);

          setResults(uniqueOptions);
        } else {
          setResults([]);
        }
      })
      .catch((err) => {
        console.log(err, "nominatim request error");
        alert("Κάτι πήγε στραβά! Δοκιμάστε ξανά σε λίγο");
        setResults([]);
      });
  }, 400);

  return (
    <Autocomplete
      id="geocoder"
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        let { bbox } = newValue;
        if (bbox)
          flyTo([
            [bbox[0], bbox[2]],
            [bbox[1], bbox[3]],
          ]);
      }}
      inputValue={valueInput}
      onInputChange={(event, newInputValue) => {
        setValueInput(newInputValue);
        handleSearch(newInputValue);
      }}
      filterOptions={(x) => x}
      sx={{
        width: { xs: 320, sm: 320, md: 350, lg: 380, xl: 380 },
        zIndex: 401,
        position: "absolute",
        top: "10px",
        right: "10px",
        backgroundColor: "white",
        padding: "9px",
        borderRadius: "10px",
        boxShadow: "2px 2px 4px 2px grey",
      }}
      options={results}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.name
      }
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField {...params} label="Εύρεση τοποθεσιών..." />
      )}
    />
  );
}

export default Geocoder;
