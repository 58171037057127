import { MapContainer, TileLayer, Marker } from "react-leaflet";
import { useRef, useEffect } from "react";
import * as L from "leaflet";
import VillagesList from "./VillagesList";
import { Box } from "@mui/material";


import "leaflet/dist/leaflet.css";

import iconMarker from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

let DefaultIcon = L.icon({
    iconUrl: iconMarker,
    shadowUrl: iconShadow,
  });
  
  L.Marker.prototype.options.icon = DefaultIcon;

function NestedMap({ villages, boundingBox }) {

  const newMap = useRef(null);

  useEffect(() => {
    if (boundingBox?.length > 0) {
      const minLng = boundingBox[0];
      const minLat = boundingBox[1];
      const maxLng = boundingBox[2];
      const maxLat = boundingBox[3];

      const leafletBounds = L.latLngBounds([minLat, minLng], [maxLat, maxLng]);

      if (newMap?.current) {
        newMap.current.fitBounds(leafletBounds, {
          maxZoom: 12,
        });
      }
    }
  }, [boundingBox]);

  const goToMarker = (el) => {
    if (el?.lon && el?.lat && newMap?.current) {
      newMap.current.setView([el.lat, el.lon], 12);
    }
  };
  

  return (
    <>
      <MapContainer
        ref={newMap}
        center={[39.04404, 23.73744]}
        scrollWheelZoom={true}
        zoom={12}
        style={{ height: "500px", width: "100%", marginTop: "40px" }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://api.mapbox.com/styles/v1/mapbox/outdoors-v12/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoic3ZjLW9rdGEtbWFwYm94LXN0YWZmLWFjY2VzcyIsImEiOiJjbG5sMnExa3kxNTJtMmtsODJld24yNGJlIn0.RQ4CHchAYPJQZSiUJ0O3VQ"
        />
        {villages.map((el, idx) => {
          return <Marker key={`marker_${idx}`} position={[el.lat, el.lon]} />;
        })}
      </MapContainer>
      <VillagesList
        villages={villages}
        goToMarker={goToMarker}
      />
    </>
  );
}

export default NestedMap;
