import { useState } from "react";

import { IconButton, Stack, Tooltip } from "@mui/material";
import { MenuBook, StickyNote2 } from "@mui/icons-material";

import Info from "./Info";

function SideBar() {
  const [infoId, setInfoId] = useState("");

  const handleInfoModal = (id) => {
    setInfoId(id);
  };

  return (
    <>
      <Info
        open={Boolean(infoId)}
        title={
          infoId === "1"
            ? "Μεθοδολογία Υλοποίησης"
            : "Οδηγίες Χρήσης"
        }
        setInfoId={setInfoId}
      />
      <Stack
        direction={"column"}
        rowGap={3}
        p={1}
        sx={{
          position: "absolute",
          right: 0,
          top: "30%",
          zIndex: 499,
          backgroundColor: "white",
          borderTopLeftRadius: "15px",
          borderBottomLeftRadius: "15px",
          boxShadow: "2px 2px 4px 2px grey",
        }}
      >
        <Tooltip title={"Μεθοδολογία Υλοποίησης"} placement="left">
          <IconButton
            onClick={() => handleInfoModal("1")}
            size="small"
            aria-label="close"
          >
            <MenuBook />
          </IconButton>
        </Tooltip>
        <Tooltip title={"Οδηγίες Χρήσης"} placement="left">
          <IconButton
            onClick={() => handleInfoModal("2")}
            size="small"
            aria-label="close"
          >
            <StickyNote2 />
          </IconButton>
        </Tooltip>
      </Stack>
    </>
  );
}

export default SideBar;
