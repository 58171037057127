import { Grid, Box } from "@mui/material";
import { useMemo } from "react";

function InfoGrid({ transcript }) {
  const payload = useMemo(() => {
    const { title, writer, date, page } = transcript;
    const header = {
      title: "Τίτλος",
      writer: "Συγγραφέας",
      date: "Χρονολογία",
      page: "Σελίδες",
    };

    return [header, { title, writer, date, page }];
  }, [transcript]);

  const headerStyle = {
    fontWeight: 700,
    borderBottom: "3px solid #A52A2A",
    paddingBottom: "10px",
    marginBottom: "20px",
  };

  return (
    <Box mt={5}>
      {payload.map((row, idx) => (
        <Grid
          key={`${idx}_container`}
          sx={idx === 0 ? headerStyle : {}}
          container
        >
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            {row.title}
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
            {row.writer}
          </Grid>
          <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
            {row.date}
          </Grid>
          <Grid item xs={12} sm={1} md={1} lg={1} xl={1}>
            {row.page}
          </Grid>
        </Grid>
      ))}
    </Box>
  );
}

export default InfoGrid;
