import { useCallback, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import InfoFirst from "./InfoFirst";
import InfoSecond from "./InfoSecond";
import InfoGrid from "./InfoGrid";

function InfoWindow({ codes, clearSelection, selectedFeature }) {
  const [transcript, setTranscript] = useState(null);

  const getTranscript = useCallback((transcript) => {

    const row = codes.get(transcript.code)

    setTranscript({
      ...transcript,
      ...row,
      locationEn: selectedFeature?.name_en || selectedFeature.LABEL_EN || selectedFeature?.NOMOS,
      location: selectedFeature.name_excel || selectedFeature.LABEL_GR || selectedFeature?.NOMOS,
    });
  }, [selectedFeature, codes]);

  const clearTranscript = () => {
    setTranscript(null);
  };

  useEffect(() => {
    if (selectedFeature) {
      setTranscript(null);
    }
  }, [selectedFeature]);

  return (
    <>
      {selectedFeature && (
        <Stack
          direction={"column"}
          sx={{
            width: {xs: 'calc(100% - 40px)', sm: 'calc(100% - 40px)', md: '700px', lg: '700px', xl: '700px'},
            height: "calc(100% - 60px)",
            background: "white",
            zIndex: 500,
            position: "absolute",
            right: 0,
            bottom: 0,
            borderLeft: "5px solid brown",
            padding: "40px 20px 20px 20px",
          }}
        >
          {transcript ? (
            <InfoSecond
              transcript={transcript}
              clearTranscript={clearTranscript}
            >
            <InfoGrid transcript={transcript} />
            </InfoSecond>
          ) : (
            <InfoFirst
              selectedFeature={selectedFeature}
              getTranscript={getTranscript}
              clearSelection={clearSelection}
            />
          )}
        </Stack>
      )}
    </>
  );
}

export default InfoWindow;
