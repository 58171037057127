import { useState, useRef, useEffect } from "react";
import { FeatureGroup, TileLayer, useMapEvents, GeoJSON } from "react-leaflet";
import { MemoizedTopoJSONBaseLevel } from "./data/TopoJSONBaseLevel";
import { MemoizedTopoJSONFirstLevel } from "./data/TopoJSONFirstLevel";
import * as L from "leaflet";
import { useContext } from "react";
import { LocaleContext } from "./../App";

import topojson from "./../assets/second_level_polygons_with_nomoi_enriched.json";
import topojsonFirstLevel from "./../assets/first_level_poly_enriched.json";
import GeoJsonFirstLevelPoints from "./../assets/first_level_points_enriched.json";

import FirstLevelLabels from "./../assets/first_level_poly_labels.json";
import BaseLevelLabels from "./../assets/second_level_poly_labels.json";

const color = "#808080";

function MapBody({ iniZoom, zoomTo, getFeature, getMapBounds }) {
  const [zoomLevel, setZoomLevel] = useState(iniZoom);
  const labelsFirstRef = useRef(null);
  const labelsBaseRef = useRef(null);

  const locale = useContext(LocaleContext);

  const map = useMapEvents({
    zoomend: () => {
      const zoom = map.getZoom();
      setZoomLevel(zoom);
    },
  });

  function onEachFeature(feature, layer) {
    const { name_excel, name_en, label } = feature.properties;
    if (name_excel === "ΙΜΒΡΟΣ-ΤΕΝΕΔΟΣ" || name_excel === "ΕΛΛΑΣ") {
      if (locale === "gr") {
        layer
          .bindTooltip(label || name_excel, {
            permanent: true,
            direction: "left",
            className: "tooltipLabel",
          })
          .openTooltip();
      } else {
        layer
          .bindTooltip(label || name_en, {
            permanent: true,
            direction: "left",
            className: "tooltipLabel",
          })
          .openTooltip();
      }
    } else {
      if (locale === "gr") {
        layer
          .bindTooltip(name_excel, {
            permanent: true,
            direction: "right",
            className: "tooltipLabel",
          })
          .openTooltip();
      } else {
        layer
          .bindTooltip(name_en, {
            permanent: true,
            direction: "right",
            className: "tooltipLabel",
          })
          .openTooltip();
      }
    }
  }

  useEffect(() => {
    if (zoomLevel >= 7 && zoomLevel < 9) {
      labelsFirstRef.current.eachLayer((layer) => {
        const { Region } = layer.feature.properties;
        layer
          .bindTooltip(Region, {
            permanent: true,
            direction: "center",
            className: "tooltipLabel",
          })
          .openTooltip();
      });
    } else {
      labelsFirstRef.current.eachLayer((layer) => {
        layer.unbindTooltip();
      });
    }
  }, [zoomLevel]);

  useEffect(() => {
    if (zoomLevel >= 9) {
      labelsBaseRef.current.eachLayer((layer) => {
        const { PREFECTURE } = layer.feature.properties;
        if (
          (PREFECTURE === "ΜΑΔΟΥΡΗ" ||
            PREFECTURE === "ΣΠΑΡΤΗ" ||
            PREFECTURE === "ΣΚΟΡΠΙΟΣ") &&
          zoomLevel <= 10
        ) {
          layer.unbindTooltip();
          return;
        }

        if (PREFECTURE === "ΚΑΛΑΜΟΣ" && zoomLevel < 10) {
          layer.unbindTooltip();
          return;
        }

        layer
          .bindTooltip(PREFECTURE, {
            permanent: true,
            direction: "center",
            className: "tooltipLabel",
          })
          .openTooltip();
      });
    } else {
      labelsBaseRef.current.eachLayer((layer) => {
        layer.unbindTooltip();
      });
    }
  }, [zoomLevel]);

  useEffect(() => {
    getMapBounds(GeoJsonFirstLevelPoints);
  }, []);

  function pointToLayer(feature, latlng) {
    return L.circleMarker(latlng, {
      radius: 8,
      color: color,
      fillColor: "brown",
      fillOpacity: 1,
      weight: 2,
      opacity: 1,
      pane: "shadowPane",
    });
  }

  return (
    <>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://api.mapbox.com/styles/v1/mapbox/outdoors-v12/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoic3ZjLW9rdGEtbWFwYm94LXN0YWZmLWFjY2VzcyIsImEiOiJjbG5sMnExa3kxNTJtMmtsODJld24yNGJlIn0.RQ4CHchAYPJQZSiUJ0O3VQ"
      />
      <FeatureGroup>
        <GeoJSON
          ref={labelsFirstRef}
          data={FirstLevelLabels}
          interactive={false}
          pointToLayer={(feature, latlng) =>
            L.marker(latlng, { opacity: 0, interactive: false })
          }
        />
        <GeoJSON
          ref={labelsBaseRef}
          data={BaseLevelLabels}
          interactive={false}
          pointToLayer={(feature, latlng) =>
            L.marker(latlng, { opacity: 0, interactive: false })
          }
        />
      </FeatureGroup>
      <GeoJSON
        eventHandlers={{
          click: (e) => {
            zoomTo(e.layer, null, "point");
            getFeature(e.layer.feature.properties);
          },
          mouseover: (e) => {
            e?.layer.setStyle({
              radius: 11,
              weight: 4,
            });
          },
          mouseout: (e) => {
            e?.layer.setStyle({
              radius: 8,
              weight: 2,
            });
          },
        }}
        data={GeoJsonFirstLevelPoints}
        onEachFeature={onEachFeature}
        pointToLayer={pointToLayer}
      />
      {zoomLevel < 9 ? (
        <FeatureGroup
          eventHandlers={{
            click: (e) => {
              getFeature(e.layer.feature.properties);
              zoomTo(e.layer, 1, "poly");
            },
          }}
        >
          <MemoizedTopoJSONFirstLevel
            color={color}
            zoomLevel={zoomLevel}
            data={topojsonFirstLevel}
          />
        </FeatureGroup>
      ) : null}
      {zoomLevel >= 9 ? (
        <FeatureGroup
          eventHandlers={{
            click: (e) => {
              zoomTo(e.layer, 2, "poly");
              getFeature(e.layer.feature.properties);
            },
          }}
        >
          <MemoizedTopoJSONBaseLevel color={color} data={topojson} />
        </FeatureGroup>
      ) : null}
    </>
  );
}

export default MapBody;
