import * as React from "react";
import Stack from "@mui/material/Stack";
import PlaceIcon from "@mui/icons-material/Place";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import MapIcon from "@mui/icons-material/Map";
import { Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import villages from "./../assets/villages.json";
import { useContext } from "react";
import { LocaleContext } from "./../App";

function InfoFirst({ selectedFeature, getTranscript, clearSelection }) {
  function isInVillages(arr1, arr2) {
    return arr1.some((item) => arr2.includes(item["Χωριο"]));
  }

  const locale = useContext(LocaleContext);

  return (
    <>
      <IconButton
        onClick={clearSelection}
        size="small"
        aria-label="close"
        sx={{ position: "absolute", top: "10px", right: "10px" }}
      >
        <CloseIcon />
      </IconButton>
      <Stack
        sx={{
          background: "white",
          width: {xs: '100%', sm: '100%', md: '700px', lg: '700px', xl: '700px'},
          maxHeight: "100%",
          overflowX: "auto",
          overflowY: "hidden",
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"flex-end"}
          columnGap={2}
          sx={{
            paddingBottom: "10px",
            borderBottom:
              selectedFeature.CODES.length > 0 ? "2px solid brown" : "",
          }}
        >
          {selectedFeature.CODES.length > 0 && (
            <>
              <PlaceIcon style={{ color: "brown", fontSize: "2rem" }} />
              {locale === "gr" ? (
                <Typography sx={{ color: "brown", fontWeight: "bold" }}>
                  {selectedFeature?.name_excel
                    ? selectedFeature.name_excel
                    : selectedFeature.NOMOS}
                </Typography>
              ) : (
                <Typography sx={{ color: "brown", fontWeight: "bold" }}>
                  {selectedFeature?.name_excel
                    ? selectedFeature.name_en
                    : selectedFeature.NOMOS}
                </Typography>
              )}
            </>
          )}
        </Stack>
        <Stack
          direction={"column"}
          sx={{
            flexWrap: "wrap",
            maxHeight: "calc(100% - 60px)",
            paddingTop: "20px",
          }}
        >
          {selectedFeature.CODES.length > 0 ? (
            selectedFeature.CODES.map((el, idx) => (
              <Stack
                direction={"row"}
                columnGap={1}
                key={`code_${idx}`}
                sx={{ marginBottom: "5px", width: "120px" }}
              >
                <Typography
                  sx={{
                    color: "#6495ED",
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                  onClick={() => getTranscript(el)}
                >
                  {el.code}
                </Typography>
                <TextSnippetIcon sx={{ color: "#767676" }} />
                {isInVillages(villages, el.village) && (
                  <MapIcon sx={{ color: "#767676" }} />
                )}
              </Stack>
            ))
          ) : (
            <Stack>
              <Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                {locale === "gr"
                  ? "Δεν υπάρχουν χειρόγραφα σε αυτή την περιοχή."
                  : "There are no transcripts in this Area."}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </>
  );
}

export default InfoFirst;
