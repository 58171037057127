import { useRef, useEffect } from "react";
import { GeoJSON } from "react-leaflet";
import * as topojson from "topojson-client";
import { useContext } from "react";
import { LocaleContext } from "./../../App";
import React from "react";

function TopoJSONFirstLevel(props) {
  const layerRef = useRef(null);
  const { data, ...otherProps } = props;

  const locale = useContext(LocaleContext);

  /* const minMax = function (latlon) {
    let xMin = 0;
    let yMin = 0;

    let xMax = 0;
    let yMax = 0;

    console.log(xMin, 'lat lon')

    return function () {
      let { lat: minLat, lng: minLng } = latlon._southWest;
      let { lat: maxLat, lng: maxLng } = latlon._northEast;

      let minX = Math.min(xMin, minLng);
      let minY = Math.min(yMin, minLat);

      let maxX = Math.max(xMax, maxLng);
      let maxY = Math.max(yMax, maxLat);

      xMin = minX;
      yMin = minY;

      xMax = maxX;
      yMax = maxY;

    };
  }; */

  function addData(layer, jsonData) {
    if (jsonData.type === "Topology") {
      for (let key in jsonData.objects) {
        let geojson = topojson.feature(jsonData, jsonData.objects[key]);
        layer.addData(geojson);
      }
    } else {
      layer.addData(jsonData);
    }
  }

  useEffect(() => {
    const layer = layerRef.current;
    addData(layer, props.data);
  }, [props.data]);

  return (
    <GeoJSON
      color={props.color}
      fillOpacity={0.1}
      weight={2}
      ref={layerRef}
      {...otherProps}
      eventHandlers={{
        mouseover: (e) => {
          e?.layer.setStyle({
            weight: 4,
            fillColor: "#D3D3D3",
            fillOpacity: 0.7,
          });
        },
        mouseout: (e) => {
          e?.layer.setStyle({
            weight: 2,
            color: "#808080",
            fillColor: "#808080",
            fillOpacity: 0.1,
          });
        },
      }}
    />
  );
}

export const MemoizedTopoJSONFirstLevel = React.memo(TopoJSONFirstLevel);
