import { Stack, Typography, Box } from "@mui/material";
import MapIcon from "@mui/icons-material/Map";

const Separator = () => (
  <Box
    sx={{
      borderRadius: "50%",
      width: "5px",
      height: "5px",
      background: "brown",
    }}
  />
);

function VillagesList({ villages, goToMarker }) {

  const handleMouseOver = (e) => {
    e.target.style.color = "#247DC9";
  };

  const handleMouseOut = (e) => {
    e.target.style.color = "brown";
  };

  return (
    <Stack
      key={"villages_description"}
      direction={"row"}
      columnGap={0.5}
      alignItems={"center"}
      sx={{ flexWrap: "wrap" }}
    >
      <MapIcon
        style={{
          color: "brown",
          fontSize: "2rem",
          marginRight: "16px",
        }}
      />
      {villages.map((el, idx) => (
        <>
          <Typography
            key={`villageName_${idx}`}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onClick={() => goToMarker(el)}
            sx={{
              color: "brown",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            {el["Χωριο"]}
          </Typography>
          {idx + 1 !== villages.length && (
            <Separator key={`sepataror_${idx}`} />
          )}
        </>
      ))}
    </Stack>
  );
}

export default VillagesList;
