import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { elGR } from '@mui/material/locale';
import './index.css'

const theme = createTheme(
  {
    palette: {
      primary: { main: '#1976d2' },
    },
  },
  elGR,
);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
    <App />
    </ThemeProvider>
  </React.StrictMode>,
)
