import { useState, useRef, useMemo } from "react";

import { MapContainer, ZoomControl } from "react-leaflet";

import Stack from "@mui/material/Stack";

import MapBody from "./MapBody";
import Header from "./Header";
import InfoWindow from "./InfoWindow";
import Geocoder from "./Geocoder";
import transcripts from "./../assets/transcripts.json";

import "leaflet/dist/leaflet.css";
import SideBar from "./SideBar";

function Mappy({ getLocale }) {
  const [iniZoom] = useState(6);
  const [selectedFeature, setSelectedFeature] = useState(null);

  const mapRef = useRef(null);

  const codesMap = useMemo(() => {
    const mapCds = new Map();
    transcripts.data.forEach((el) => {
      const { code, ...rest } = el;
      mapCds.set(code, rest);
    });

    return mapCds;
  }, []);

  const getFeature = (feature) => {
    setSelectedFeature(feature);
  };

  const clearSelection = () => {
    setSelectedFeature(null);
  };

  const zoomTo = (layer, base, type) => {
    if (type === "poly") {
      const layerCenter = layer.getBounds().getCenter();

      const centerInPx = mapRef.current.latLngToContainerPoint(layerCenter);

      const newCenterInPx = {
        x: base === 1 ? centerInPx.x + 150 : centerInPx.x + 250,
        y: centerInPx.y,
      };

      const newCenterInCoords =
        mapRef.current.containerPointToLatLng(newCenterInPx);

      if (base === 1) {
        mapRef.current.setView(newCenterInCoords, 8);
      } else {
        mapRef.current.setView(newCenterInCoords, 10);
      }
    } else if (type === "point") {
      const centerInPx = mapRef.current.latLngToContainerPoint([
        layer?._latlng.lat,
        layer?._latlng.lng,
      ]);

      const newCenterInPx = {
        x: centerInPx.x + 150,
        y: centerInPx.y,
      };

      const newCenterInCoords =
        mapRef.current.containerPointToLatLng(newCenterInPx);
      mapRef.current.setView(newCenterInCoords, 8);
    }
  };

  const flyTo = (coords) => {
    mapRef.current.fitBounds(coords, { padding: [20, 20], maxzoom: 16 });
  };

  const getMapBounds = (layer) => {
    const { features } = layer;
    const grouped = features.reduce(
      (acc, feature) => {
        const { geometry } = feature;
        const { coordinates } = geometry;
        const [lon, lat] = coordinates;

        if (!acc.southWest.lat) {
          acc.southWest = {
            lat,
            lon,
          };

          acc.northEast = {
            lat,
            lon,
          };
        }

        let minX = Math.min(acc.southWest.lon, lon);
        let minY = Math.min(acc.southWest.lat, lat);

        let maxX = Math.max(acc.northEast.lon, lon);
        let maxY = Math.max(acc.northEast.lat, lat);

        acc.southWest = {
          lat: minY,
          lon: minX,
        };

        acc.northEast = {
          lat: maxY,
          lon: maxX,
        };

        return acc;
      },
      {
        southWest: {
          lat: null,
          lon: null,
        },
        northEast: {
          lat: null,
          lon: null,
        },
      }
    );

    if (mapRef?.current) {
      const { southWest, northEast } = grouped;
      mapRef.current.fitBounds([
        [southWest.lat, southWest.lon],
        [northEast.lat, northEast.lon],
      ]);
    }
  };

  return (
    <>
      <Header getLocale={getLocale} />
      <Stack
        sx={{
          height: "calc(100vh - 80px)",
          width: "100%",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <SideBar />
        <InfoWindow
          codes={codesMap}
          clearSelection={clearSelection}
          selectedFeature={selectedFeature}
        />
        <Geocoder flyTo={flyTo} />
        <MapContainer
          zoomControl={false}
          ref={mapRef}
          center={[39.04404, 23.73744]}
          style={{ height: "100%", width: "100vw" }}
          minZoom={6}
        >
          <MapBody
            iniZoom={iniZoom}
            zoomTo={zoomTo}
            getFeature={getFeature}
            getMapBounds={getMapBounds}
          />
          <ZoomControl zoom={iniZoom} position="bottomright" />
        </MapContainer>
      </Stack>
    </>
  );
}

export default Mappy;
